import { InvitationSuccessfulUI } from "modules";

const InvitationSuccessful = () => {
  return (
    <>
      <InvitationSuccessfulUI />
    </>
  );
};

export { InvitationSuccessful };
