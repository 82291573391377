import { RecaptchaUI } from "modules";

const Recaptcha = () => {
  return (
    <>
      <RecaptchaUI />
    </>
  );
};

export { Recaptcha };
