import { MemberProfileTasksUI } from "modules";

const MemberProfileTasks = () => {
  return (
    <>
      <MemberProfileTasksUI />
    </>
  );
};

export { MemberProfileTasks };
