import { MemberProfileCommentsUI } from "modules";

const MemberProfileComments = () => {
  return (
    <>
      <MemberProfileCommentsUI />
    </>
  );
};

export { MemberProfileComments };
