import { MemberProfileDetailsUI } from "modules";

const MemberProfileDetails = () => {
  return (
    <>
      <MemberProfileDetailsUI />
    </>
  );
};

export { MemberProfileDetails };
