import { MemberProfileFilesUI } from "modules";

const MemberProfileFiles = () => {
  return (
    <>
      <MemberProfileFilesUI />
    </>
  );
};

export { MemberProfileFiles };
