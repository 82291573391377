import { Error500UI } from "modules";

const Error500 = () => {
  return (
    <>
      <Error500UI />
    </>
  );
};

export { Error500 };
