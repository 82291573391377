import { MemberProfileNotesUI } from "modules";

const MemberProfileNotes = () => {
  return (
    <>
      <MemberProfileNotesUI />
    </>
  );
};

export { MemberProfileNotes };
