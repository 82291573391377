import { MemberProfileClientsUI } from "modules";

const MemberProfileClients = () => {
  return (
    <>
      <MemberProfileClientsUI />
    </>
  );
};

export { MemberProfileClients };
