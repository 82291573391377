import { Error404UI } from "modules";

const Error404 = () => {
  return (
    <>
      <Error404UI />
    </>
  );
};

export { Error404 };
