import { MemberProfileEmailsUI } from "modules";

const MemberProfileEmails = () => {
  return (
    <>
      <MemberProfileEmailsUI />
    </>
  );
};

export { MemberProfileEmails };
