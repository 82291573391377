import { OrgBankAccountsUI } from "modules";

const OrgBankAccounts = () => {
  return (
    <>
      <OrgBankAccountsUI />
    </>
  );
};

export { OrgBankAccounts };
